<template>
    <div class="single-channel-image" v-for="(publish, index) in cat_info" :key="index">
        <img class="img-fluid harry" alt :src="publish.img">
        <div class="channel-profile">
            <img class="channel-profile-img" alt :src="publish.img">
            <!-- <div class="social hidden-xs">
                Social &nbsp;
                <a class="fb" href="#">Facebook</a>
                <a class="tw" href="#">Twitter</a>
                <a class="gp" href="#">Google</a>
            </div> -->
        </div>
    </div>
    <div class="container-fluid" style="margin-top: 4vh;">
        <div class="video-block section-padding">
            <div class="row">
                <div class="col-md-12">
                    <div class="main-title">
                        <!-- <div class="btn-group float-right right-action">
                            <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Sort by <i class="fa fa-caret-down" aria-hidden="true"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                                <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                                <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                            </div>
                        </div> -->
                        <h6>{{ $route.params.name_cat }}</h6>
                    </div>
                </div>
                <div class="col-6 col-sm-4 col-lg-3 col-xl-2" v-for="(publish, index) in categorie" :key="index">
                    <div class="video-card">
                        <div class="video-card-image">
                            <a class="play-icon" @click="getFeed(publish)"><i class="fas fa-play-circle"></i></a>
                            <a @click="getFeed(publish)"><img class="img-fluid" v-lazy="publish.img" alt></a>
                            <div class="time">{{ publish.file_duration }}</div>
                        </div>
                        <div class="video-card-body">
                            <div class="video-title">
                                <a @click="getFeed(publish)">{{ publish.title_ar }}</a>
                            </div>
                            <div class="video-page text-success">
                                {{ publish.name_ar }}  <a title data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                            </div>
                            <div class="video-view">
                               &nbsp;<i class="fas fa-calendar-alt"></i> {{ publish.upload_date.substring(0,10) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center pagination-sm mb-0">
                    <li class="page-item disabled">
                        <a tabindex="-1" href="#" class="page-link">Previous</a>
                    </li>
                    <li class="page-item active"><a href="#" class="page-link">1</a></li>
                    <li class="page-item"><a href="#" class="page-link">2</a></li>
                    <li class="page-item"><a href="#" class="page-link">3</a></li>
                    <li class="page-item">
                        <a href="#" class="page-link">Next</a>
                    </li>
                </ul>
            </nav> -->
        </div>
    </div>
</template>

<script>
import { HTTP } from "@/Api/http-common";
import { ref } from 'vue';
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
export default {
    name: 'AppCategorys',
    props:{
        id: String,
        name_cat: String
    },
    async setup(props) {
        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie()
        const categorie = ref([]);
        const cat_info = ref([]);

        try {
            await HTTP.get(`GetCategoryInfo.php?ID=${props.id}`).then((res) => {
                cat_info.value = res.data.CategoryInfo;
            });
        } catch (err) {
            console.log(err);
        }

        try {
            await HTTP.get(`GetContentByCategory.php?cat_id=${props.id}&LIMIT=18&OFFSET=0`).then((res) => {
                categorie.value = res.data.Content;
            });
        } catch (err) {
            console.log(err);
        }
        const getFeed = (publish) => {
            cookie.setCookie('cat_id', publish.cat_id);
            router.push({ name: "Contents", params: { id: publish.id } });
        };
        return { categorie, cat_info ,getFeed}
    }
}
</script>

<style>
@media(max-width:575.98px) {
    .harry {
        height: 13rem;
    }
}
@media(min-width:576px) and (max-width:767.98px) {
    .harry {
        height: 15rem;
    }
}
@media(min-width:768px) and (max-width:991.98px) {}
    .harry {
        height: 15rem;
    }
@media(min-width:992px) and (max-width:1199.98px) {
    .harry {
        height: 20rem;
    }
}
@media(min-width:1200px) {
    .harry {
        height: 30rem;
    }
}
</style>